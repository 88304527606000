import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {
    Grid,
    IconButton,
    Menu,
    MenuItem, Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import {EmptyScreen} from "../../CommonScreens/EmptyScreen";
import {
    formatDateAndTimeForAlerts,
    formattedEpochData,
    getImageName,
    isEmpty,
    reduceString
} from "../../../utils/helper";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import {ALERT_SEVERITY_COLORS} from "../../../Constant";
import {AlertsSeverityIcons} from "../../../assests/icons/AlertsSeverityIcons";
import "./index.css";
import {AlertsTypeIcons} from "../../../assests/icons/AlertsTypeIcons";
import {AlertsStatusIcons} from "../../../assests/icons/AlertsStatusIcons";
import {RequestContext} from "../../../Context/RequestContext";

function DynamicTable({
                          tableContent = [],
                          tableConfig = [],
                          isLoading = false,
                          highlightUnseen = false,
                          transparentRow = false,
                          applyBorder = false,
                          onClick,
                          onSort,
                          onClickAction,
                          emptyStateMessage,
                          forAlertDetailPage,
                          userData,
                      }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [sortOrder, setSortOrder] = useState("descending");
    const {updateCurrentSelectedAlert} = useContext(RequestContext)

    const handleMenuOpen = (event, data) => {
        setAnchorEl(event.currentTarget);
        setSelectedOption(data);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedOption(null);
    };

    const toggleSortOrder = (column) => {
        const newSortOrder = sortOrder === "ascending" ? "descending" : "ascending";
        setSortOrder(newSortOrder);
        onSort && onSort(column.key, newSortOrder);
    };

    const handleActions = (option, data) => {
        handleMenuClose();
        onClickAction && onClickAction(option, data);
    };

    const renderActionMenu = (data, config) => {
        let actionOptions = [];

        if( config.key === "user_management_actions" ){
            if( data.user_status === "pending" ){ actionOptions = config.options.filter( option => option !== "Change role" && option !== "Delete user" ); }
            else{ actionOptions = config.options.filter( option => option !== "Resend invite" ); }
        }
        else if (config.key === "alert_rules_actions") {
            actionOptions = data.status === "Disabled" ? ["Edit", "Clone", "Enable", "Delete"] : ["Edit", "Clone", "Disable", "Delete"];
        }
        else {
            actionOptions = data.current_status === "Paused" ? ["Resume alert"] : ["Pause alert"];
        }
        return (
            <div className="dynamic-last-table-cell">
                {
                    config?.disableSelfEdit ? data?.user_name === userData.email ? null : (
                        <React.Fragment>
                            <IconButton onClick={(e) => handleMenuOpen(e, data)}>
                                <MoreVertOutlinedIcon style={{ color: "#e7e7e7" }} />
                            </IconButton>
                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl) && selectedOption === data} onClose={handleMenuClose}>
                                {actionOptions.map((option, index) => (
                                    <MenuItem key={index} onClick={() => handleActions(option, data)}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <IconButton onClick={(e) => handleMenuOpen(e, data)}>
                                <MoreVertOutlinedIcon style={{ color: "#e7e7e7" }} />
                            </IconButton>
                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl) && selectedOption === data} onClose={handleMenuClose}>
                                {actionOptions.map((option, index) => (
                                    <MenuItem key={index} onClick={() => handleActions(option, data)}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </React.Fragment>
                    )
                }
            </div>
        );
    };

    const renderCellContent = (data, config) => {
        let value;
        if(forAlertDetailPage){
            value =  reduceString(data[config.key], config.maxAllowedChars) || 0;
        }else{
            value =  reduceString(data[config.key], config.maxAllowedChars) || "";
        }
        // const value =  reduceString(data[config.key], config.maxAllowedChars) || "";
        const cellStyle = { color: config.textColor || "inherit", cursor: config.clickable ? "pointer" : "default", padding: config?.padding ? config.padding : "0px" };
        let avatarName;
        let dateTop;
        let timeBottom;

        if ((config.key === "actions" || config.key === "user_management_actions" || config.key === "alert_rules_actions") && config.allowActions) {
            return renderActionMenu(data, config);
        }
        if (config.type === "name_with_avatar") {
            avatarName = getImageName(data[config.key]);
        }
        if (config.type === "date") {
            dateTop = formattedEpochData(data[config.key]).top;
            timeBottom = formattedEpochData(data[config.key]).bottom;
        }
        if (config.type === "date_for_alerts") {
            dateTop = formatDateAndTimeForAlerts(data[config.key]).date;
            timeBottom = formatDateAndTimeForAlerts(data[config.key]).time + " IST";
        }

        const contentByType = {
            severity: (
                <div style={cellStyle} className="dynamic-table-severity">
                    {AlertsSeverityIcons(typeof value === "string" ? value : "")}
                    <span style={{ color: ALERT_SEVERITY_COLORS[value] }}>{value}</span>
                </div>
            ),
            pause_end_timestamp: (
                <div style={cellStyle} className="dynamic-table-status">
                    <span>Paused till</span>
                    <span className="secondary-text-data">{formattedEpochData(data.pause_end_timestamp).top}</span>
                </div>
            ),
            date: (
                <div style={cellStyle} className="dynamic-table-date">
                    <span>{dateTop}</span>
                    <span className="secondary-text-data">{timeBottom}</span>
                </div>
            ),
            date_for_alerts: (
                <div style={cellStyle} className="dynamic-table-date">
                    <span>{dateTop}</span>
                    <span className="secondary-text-data">{timeBottom}</span>
                </div>
            ),
            alert_type: (
                <div style={cellStyle} className="dynamic-table-severity">
                    {AlertsTypeIcons(typeof value === "string" ? value : "")}
                    <span style={{color: value === "Disabled" ? "#999": "" }} >{value}</span>
                </div>
            ),
            // status: (
            //     <div style={cellStyle} className="dynamic-table-severity">
            //         {AlertsStatusIcons(typeof value === "string" ? value : "")}
            //         <span style={{ color: value === "Disabled" ? "#999": "#82C77F"}} >{value}</span>
            //     </div>
            // ),
            status: (
                <div style={cellStyle} className="dynamic-table-severity">
                    {AlertsStatusIcons(value ? "Enabled" : "Disabled")}
                    <span style={{ color: value ? "#82C77F" : "#999" }}>{value ? "Enabled" : "Disabled"}</span>
                </div>
            ),
            status_boolean: (
                <div style={cellStyle} className="dynamic-table-severity">
                    {AlertsStatusIcons(typeof value === "string" ? value : "")}
                    <span style={{ color: value === "pending" ? "#999": "#82C77F"}} >{ value === "active" ? "Active" : "Signup pending"}</span>
                </div>
            ),
            name_with_avatar: (
                <div style={cellStyle} className="dynamic-table-name">
                    <div className="user-logo">
                        {avatarName}
                    </div>
                    <span>{value} {userData && userData.email === data.user_name ? "(You)": null} </span>
                </div>
            ),
        };

        return contentByType[config.type] || (
            <React.Fragment>
                {config.showTooltip ? (
                    //add alert id in path
                    <Tooltip title={data[config.key]} placement="bottom" arrow>
                        <span style={cellStyle} onClick={() => {
                            if(config.clickable && onClick){
                                updateCurrentSelectedAlert(data);
                                onClick(`/dashboard/alerts/view-details/${data?.alert_id}`);
                            }
                        }}>
                            {value}
                        </span>
                    </Tooltip>
                ) : (
                    <span style={cellStyle} onClick={ config.clickable && onClick(data)}>{value}</span>
                )}
            </React.Fragment>
        );
    };

    const renderTableRows = () => {
        if (isLoading) {
            return (
                <>
                    {[...Array(11)].map((_, index) => (
                        <TableRow className="dynamic-table-lazy-loading">
                            {[...Array(tableConfig.length)].map((_, index) => (
                                <TableCell key={`${Math.random()}-${index}`} className="dynamic-table-lazy-loading-cell" >
                                    <Grid className="empty-screen-grid">
                                        <Skeleton key={index} className="skleton-loading-screen" animation="wave" />
                                    </Grid>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </>
            );
        }

        if (tableContent.length > 0) {
            return tableContent.map((data, index) => (
                <TableRow
                    key={index}
                    className={highlightUnseen ? (data.is_read ? "unseen-row" : "seen-row") : transparentRow ? "transparent-row" : "unseen-row" }
                >
                    {tableConfig.map((config, colIndex) => (
                        <TableCell key={colIndex} style={{ width: config.width }}>
                            {renderCellContent(data, config)}
                        </TableCell>
                    ))}
                </TableRow>
            ));
        }

        const message = !isEmpty(tableContent) ? "No results found" : emptyStateMessage;
        return (
            <TableRow>
                <TableCell colSpan={tableConfig.length}>
                    <EmptyScreen message={message} />
                </TableCell>
            </TableRow>
        );
    };

    return (
        <Grid item xs={12}>
            <TableContainer className={`dynamic-table-container ${applyBorder ? "table-with-border-radius" : ""}`} >
                <Table className="dynamic-off-white-table">
                    <TableHead >
                        <TableRow>
                            {tableConfig.map((column, index) => (
                                <TableCell
                                    key={index}
                                    style={{ width: column.width, whiteSpace: "nowrap" }}
                                    className={column.key === "actions" ? "dynamic-last-table-cell-heading" : ""}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {column.label}
                                        {column.sortIcon && (
                                            <IconButton onClick={() => toggleSortOrder(column)} style={{ marginLeft: 4 }}>
                                                {sortOrder === "ascending" ? (
                                                    <ArrowUpwardIcon fontSize="small" style={{ color: "#FDA560" }} />
                                                ) : (
                                                    <ArrowDownwardIcon fontSize="small" style={{ color: "#FDA560" }} />
                                                )}
                                            </IconButton>
                                        )}
                                        {column.infoIcon && (
                                            <Tooltip title={column.infoText || ""} placement="top" arrow>
                                                <InfoOutlinedIcon fontSize="small" style={{ marginLeft: 4, cursor: "pointer" }} />
                                            </Tooltip>
                                        )}
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>{renderTableRows()}</TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
}

DynamicTable.propTypes = {
    tableContent: PropTypes.arrayOf(PropTypes.object),
    tableConfig: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            width: PropTypes.string,
            sortIcon: PropTypes.bool,
            type: PropTypes.string,
            textColor: PropTypes.string,
            clickable: PropTypes.bool,
            infoIcon: PropTypes.bool,
            allowActions: PropTypes.bool,
        })
    ).isRequired,
    isLoading: PropTypes.bool,
    highlightUnseen: PropTypes.bool,
    onClick: PropTypes.func,
    searchedText: PropTypes.string,
    onSort: PropTypes.func,
    onClickAction: PropTypes.func,
};


export default DynamicTable;
