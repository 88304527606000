import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import "./index.css";
import {Chip, Grid, Skeleton, Typography} from "@mui/material";
import Notification from "../../../Components/Notification";
import SettingsIcon from "../../../assests/icons/SettingsIcon";
import BellIcon from "../../../assests/icons/BellIcon";
import VectorFullWidthIcon from "../../../assests/icons/vectorFullWidthIcon";
import DynamicTable from "../../../Components/Tables/DynamicTable";
import {alertsTableConfig} from "../../../Configs/AlertsConfig/AlertsTable";
import {alertsTableContent} from "../../../Configs/AlertsConfig/MockData";
import DynamicTableTopFilter from "../../../Components/TableTopFilters/DynamicTableTopFilter";
import CustomPagination from "../../../Common/CustomPagination";
import {getAlertsAggregatedStats} from "../../../api/getAlertsAggregatedStats";
import {getAllAlertsPaginated} from "../../../api/getAllAlertsPaginated";
import {ALL_DATA_FETCHED, GETTING_THE_DATA, ADSPLUS_ACCOUNT_ID} from "../../../Constant";
import useDebounce from "../../../utils/useDebounce";
import {isEmpty} from "../../../utils/helper";

const VerticalVectorIcon = React.lazy(() => import('../../../assests/icons/VerticalVectorIcon'));

const AlertsDashboard = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const navigation = useNavigate();
    const [summaryData, setSummaryData] = useState([]);
    const [chipFilterData, setChipFilterData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tableDataLoading, setTableDataLoading] = useState(true);
    const [searchedData, setSearchedData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [pageSize, setPageSize] = useState(20);
    const [page, setPage] = useState(1);
    const [totalAlertCount, setTotalAlertCount] = useState();
    const [selectedChips, setSelectedChips] = useState([]);
    const [filtersBody, setFiltersBody] = useState({});
    const [searchedDataLength, setSearchedDataLength] = useState();

    const paginatedData = async () => {
        setTableDataLoading(true);
        try{
            const res = await getAllAlertsPaginated(pageSize, page, filtersBody);
            !totalAlertCount && setTotalAlertCount(res?.total || 0);
            setSearchedData(res?.data);
            setSearchedDataLength(res?.total);
            setNotificationMessage(ALL_DATA_FETCHED);
            return res?.data;
        }
        catch (e) {
            setNotificationMessage(e?.message);
            setAlertType('error');
        }
        finally {
            setTableDataLoading(false);
        }
    };

    useEffect( () => {

        const getData = async () => {
            setLoading(true);
            setNotificationMessage(GETTING_THE_DATA);
            setAlertType('info');
            try{
                const alertStats = await getAlertsAggregatedStats(ADSPLUS_ACCOUNT_ID)
                    .then((alerts) => {
                    return alerts?.data
                });
                setSummaryData([
                    {count: alertStats?.total_channels_affected, label: 'Total channels affected'},
                    {count: alertStats?.total_demand_tags_affected, label: 'Total demand tags affected'},
                    {count: alertStats?.total_supply_tags_affected, label: 'Total supply tags affected'},
                    {count: alertStats?.total_paused_alerts, label: 'Paused alerts'},
                ]);
                const chipData = [
                    {label: "Critical", count: alertStats?.total_critical_alerts},
                    {label: "High", count: alertStats?.total_high_alerts},
                    {label: "Medium", count: alertStats?.total_medium_alerts},
                    {label: "Low", count: alertStats?.total_low_alerts},
                    {label: "Paused", count: alertStats?.total_paused_alerts},
                    {label: "Unseen", count: alertStats?.total_unread_alerts}
                ];
                setChipFilterData(chipData);
                setNotificationMessage(ALL_DATA_FETCHED);
                setAlertType('success');
            }
            catch (e) {
                setNotificationMessage(e?.message);
                setAlertType('error');
            }
            finally {
                setLoading(false);
            }
        }
        getData();
    }, []);

    useEffect( () => {
        paginatedData();
    }, [page, pageSize, filtersBody]);

    const handleNotificationClose = () => {
        setIsOpen(false);
    };

    const handleNavigation = (link) => {
        navigation(link);
    };

    const onSearchFilter = (text = "") => {
        setPage(1);
        setSearchText(text);
        handleDebouncedSearch(text);
    };

    const handleDebouncedSearch = useDebounce((searchText) => {
        if(searchText===""){
            setFiltersBody({...filtersBody, "alert_rule_name": ""});
            setSearchedDataLength(totalAlertCount);
            return;
        }
        let text = searchText.trim();
        !isEmpty(text) && setFiltersBody({...filtersBody, "alert_rule_name": text});
    }, 400);

    const handleSort = (columnKey, sortOrder) => {
        const sortedData = [...searchedData].sort((firstItem, secondItem) => {
            const firstValue = firstItem[columnKey];
            const secondValue = secondItem[columnKey];

            if (sortOrder === "ascending") {
                return firstValue > secondValue ? 1 : -1;
            } else {
                return firstValue < secondValue ? 1 : -1;
            }
        });
        setSearchedData(sortedData);
    };

    const handleActions = (option, data) => {
        setIsOpen(true);
        setNotificationMessage(`${option} rule for ${data?.channel_name}`);
        setAlertType('success');
    }


    const handlePageChange = (newPage, newSize) => {
        setPage(newPage);
        setPageSize(newSize);
    };

    const handlePageSizeChange = (newSize) => {
        setPageSize(newSize);
    };

    const handleChipFilterClick = (chip) => {
        const severityChips = ["Critical", "High", "Medium", "Low"];
        let updatedChips = [...selectedChips];

        if (severityChips.includes(chip)) {
            if (updatedChips.includes(chip)) {
                updatedChips = updatedChips.filter((c) => !severityChips.includes(c));
            } else {
                updatedChips = updatedChips.filter((c) => !severityChips.includes(c));
                updatedChips.push(chip);
            }
        } else {
            if (updatedChips.includes(chip)) {
                updatedChips = updatedChips.filter((c) => c !== chip);
            } else {
                updatedChips.push(chip);
            }
        }
        setSelectedChips(updatedChips);
    };

    useEffect(() => {
        let updatedFiltersBody = {};
        const severityChips = ["Critical", "High", "Medium", "Low"];
        const paused = selectedChips.includes("Paused");
        const unseen = selectedChips.includes("Unseen");

        const severity = selectedChips.filter((chip) => severityChips.includes(chip));
        if (severity.length === 1) {
            updatedFiltersBody["severity"] = severity[0];
        }

        if (paused) {
            updatedFiltersBody["pause"] = true;
        }

        if (unseen) {
            updatedFiltersBody["uread"] = true;
        }

        setFiltersBody(updatedFiltersBody);
    }, [selectedChips]);


    return (
        <React.Fragment>
            <Grid container rowSpacing={2}>
                <Grid item xs={12} className="alerts-header">
                    <Typography>Alerts</Typography>
                    <div className="alert-rule-icon" onClick={() => handleNavigation("/dashboard/alerts/rules")}>
                        <SettingsIcon />
                        <div>Alert rules</div>
                    </div>
                </Grid>

                {loading ? (
                    <Grid item xs={12}>
                        <Skeleton variant="rectangular" width={1000} height={73} className="alerts-skeleton-loading" />
                        <VectorFullWidthIcon width={"auto"} height={"6px"} />
                        <Skeleton variant="text" width={550} height={60} className="alerts-skeleton-loading" />
                        <Skeleton variant="rectangular" width="100%" height={600} className="alerts-skeleton-loading" />
                    </Grid>
                ) : totalAlertCount === 0 ? (
                    <Grid item xs={12}>
                        <div className="empty-alerts-content">
                            <BellIcon />
                            <div className="es-heading">No alerts yet</div>
                            <div className="es-sub-heading">Create new alert rules to start tracking your performance</div>
                        </div>
                    </Grid>
                ) : (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <div className="alerts-summary">
                                {summaryData?.map((item, index) => (
                                    <React.Fragment>
                                        <div key={index} className="alert-item">
                                            <Typography data-testid={`alert-item-count-${index}`}>{item.count}</Typography>
                                            <div data-testid={`alert-item-label-${index}`}>{item.label}</div>
                                        </div>
                                        {index < summaryData.length - 1 && <VerticalVectorIcon />}
                                    </React.Fragment>
                                ))}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <VectorFullWidthIcon width={"auto"} height={"6px"} />
                        </Grid>
                        <Grid item xs={12} className="chip-filter">
                            {chipFilterData.map((item, index) => (
                                <Chip
                                    key={index}
                                    label={`${item.label}: ${item.count}`}
                                    variant="outlined"
                                    onClick={() => {handleChipFilterClick(item?.label)}}
                                    className={selectedChips.includes(item.label) ? "alerts-screen-chips-selected" : "alerts-screen-chips"}
                                />
                            ))}
                        </Grid>
                        <Grid item xs={12} >
                            <Grid item xs={12} className="amg-filter-content">
                                <DynamicTableTopFilter
                                    count={searchedData.length}
                                    totalCount={searchedDataLength}
                                    page={page}
                                    pageSize={pageSize}
                                    titleText={"alerts"}
                                    onSearch={onSearchFilter}
                                    showFilter={false}
                                    filterOpen={() => setIsFilterOpen(true)}
                                    countIsPaginated={true}
                                />
                            </Grid>
                            <Grid container className="dynamic-table-content">
                                <DynamicTable
                                    tableContent={searchedData}
                                    onClick={handleNavigation}
                                    isLoading={tableDataLoading}
                                    searchedText={searchText}
                                    tableConfig={alertsTableConfig}
                                    highlightUnseen={true}
                                    onSort={handleSort}
                                    onClickAction={handleActions}
                                    emptyStateMessage={"No alerts found!"}
                                />
                            </Grid>
                        </Grid>
                        {
                            searchedDataLength > 0 && (
                                <Grid item xs={12} >
                                    <div className="amg-pagination-section" >
                                        <CustomPagination
                                            totalItems={searchedDataLength}
                                            itemsPerPage={pageSize}
                                            currentPage={page}
                                            onPageChange={handlePageChange}
                                            onPageSizeChange={handlePageSizeChange}
                                            apiDriven={false}
                                        />
                                    </div>
                                </Grid>
                            )
                        }
                    </React.Fragment>
                )}
            </Grid>
            <Notification
                open={isOpen}
                message={notificationMessage}
                handleClose={handleNotificationClose}
                type={alertType}
            />
        </React.Fragment>
    );
};

export default AlertsDashboard;