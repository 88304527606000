export const alertRulesTableConfig = [
    {
        key: "alert_rule_name",
        label: "ALERT NAME",
        textColor: "#E7E7E7",
        type: "alert_name",
        width: "800px",
        maxAllowedChars: 50,
        showTooltip: true,
        padding: "8px 0px"
    },
    {
        key: "alert_type",
        label: "ALERT TYPE",
        textColor: "#E7E7E7",
        type: "alert_type",
        width: "350px"
    },
    {
        key: "frequency",
        label: "FREQUENCY",
        textColor: "#E7E7E7",
        type: "frequency",
        width: "350px"
    },
    {
        key: "severity",
        label: "SEVERITY",
        type: "severity",
        width: "350px"
    },
    {
        key: "enabled",
        label: "STATUS",
        type: "status",
        width: "350px"
    },
    {
        key: "alert_rules_actions",
        label: " ",
        allowActions: false,
        options: [
            "Edit",
            "Clone",
            "Disable",
            "Delete",
        ],
    },
];
