import CrossIcon from "../../assests/icons/crossIcon";
import { AlertsSeverityIcons } from "../../assests/icons/AlertsSeverityIcons";
import { InfoIcon } from "../../assests/icons/InfoIcon";
import { ALERT_SEVERITY_COLORS } from "../../Constant";

export const CreateRulesFormConfig = ( supplyPartners=[], channels=[], supplyTags=[], demandTags=[], demandPartners=[] ) => [
    {
        label: "Create alert rule",
        type: "header",
        containsIcon: true,
        iconAsset: <CrossIcon />
    },
    {
        type: "vector",
    },
    {
        label: "Currently only applicable to inventory from Ads+ data source.",
        type: "info",
        iconAsset: <InfoIcon color="#7FB8F0" />,
        textColor: "#E7E7E7",
        backgroundColor: "#0E2143",
    },
    {
        label: "Alert level",
        type: "simple_select",
        key: "alert_level",
        isRequired: true,
        isMutable: true,
        placeholder: "Select alert type",
        areOptionsDependent: false,
        options: [
            { label: "All supply", value: "AllSupply" },
            { label: "All demand", value: "AllDemand" },
            { label: "Supply partner", value: "SupplyPartner" },
            { label: "Channel", value: "Channel" },
            { label: "Supply tag", value: "SupplyTag" },
            { label: "Demand tag", value: "DemandTag" },
            { label: "Demand Partner", value: "DemandPartner" },
        ],
        validation: {
            type: "required",
            errorMessage: "Alert level is required.",
        },
        inputWidth: "165px",
    },
    {
        label: "Supply partners",
        type: "multi_select_with_search",
        key: "supply_partners",
        isRequired: true,
        isMutable: true,
        dependentOn: "alert_level",
        dependentKeyValue: "SupplyPartner",
        placeholder: "Select supply partners",
        allowSelectAll: true,
        options: supplyPartners,
        validation: {
            type: "required",
            errorMessage: "Please select at least one supply partner.",
        },
    },
    {
        label: "Channels",
        type: "multi_select_with_search",
        key: "channels",
        isRequired: true,
        isMutable: true,
        dependentOn: "alert_level",
        dependentKeyValue: "Channel",
        placeholder: "Select channels",
        allowSelectAll: true,
        options: channels,
        validation: {
            type: "required",
            errorMessage: "Please select at least one channel.",
        },
    },
    {
        label: "Supply tags",
        type: "multi_select_with_search",
        key: "supply_tags",
        isRequired: true,
        isMutable: true,
        dependentOn: "alert_level",
        dependentKeyValue: "SupplyTag",
        placeholder: "Select supply tags",
        allowSelectAll: true,
        options: supplyTags,
        validation: {
            type: "required",
            errorMessage: "Please select at least one supply tag.",
        },
    },
    {
        label: "Demand tags",
        type: "multi_select_with_search",
        key: "demand_tags",
        isRequired: true,
        isMutable: true,
        dependentOn: "alert_level",
        dependentKeyValue: "DemandTag",
        placeholder: "Select demand tags",
        allowSelectAll: true,
        options: demandTags,
        validation: {
            type: "required",
            errorMessage: "Please select at least one demand tag.",
        },
    },
    {
        label: "Demand partners",
        type: "multi_select_with_search",
        key: "demand_partners",
        isRequired: true,
        isMutable: true,
        dependentOn: "alert_level",
        dependentKeyValue: "DemandPartner",
        placeholder: "Select demand partners",
        allowSelectAll: true,
        options: demandPartners,
        validation: {
            type: "required",
            errorMessage: "Please select at least one demand partner.",
        },
    },
    {
        label: "Alert name",
        type: "view_only",
        key: "alert_rule_name",
        defaultValue: '__ for last __ hrs',
        isRequired: true,
        isMutable: false,
        value: "",
    },
    {
        label: "Alert type",
        type: "simple_select",
        key: "alert_type",
        isRequired: true,
        isMutable: true,
        placeholder: "Select alert type",
        areOptionsDependent: false,
        options: [
            // { label: "Trend", value: "Trend" },
            { label: "Spot", value: "Spot" },
        ],
        validation: {
            type: "required",
            errorMessage: "Alert type is required.",
        },
        inputWidth: "165px",
    },
    {
        label: "Metric",
        type: "simple_select",
        key: "metric",
        isRequired: true,
        isMutable: true,
        placeholder: "Select metric",
        areOptionsDependent: true,
        optionsDependentOn: "alert_level",
        validOptions: {
            Channel: [
                {
                    label: "Requests",
                    value: "Requests"
                },
                {
                    label: "Impressions",
                    value: "Impressions"
                },
                {
                    label: "Revenue",
                    value: "Revenue"
                },
                {
                    label: "Render rate",
                    value: "RenderRate"
                },
                {
                    label: "Timeouts",
                    value: "Timeouts"
                },
                {
                    label: "RPM",
                    value: "RPM"
                },
                {
                    label: "Fill rate",
                    value: "FillRate"
                }
            ],
            SupplyPartner: [
                {
                    label: "Requests",
                    value: "Requests"
                },
                {
                    label: "Impressions",
                    value: "Impressions"
                },
                {
                    label: "Revenue",
                    value: "Revenue"
                },
                {
                    label: "Render rate",
                    value: "RenderRate"
                },
                {
                    label: "Timeouts",
                    value: "Timeouts"
                },
                {
                    label: "RPM",
                    value: "RPM"
                },
                {
                    label: "Fill rate",
                    value: "FillRate"
                }
            ],
            SupplyTag: [
                {
                    label: "Requests",
                    value: "Requests"
                },
                {
                    label: "Impressions",
                    value: "Impressions"
                },
                {
                    label: "Revenue",
                    value: "Revenue"
                },
                {
                    label: "Render rate",
                    value: "RenderRate"
                },
                {
                    label: "Timeouts",
                    value: "Timeouts"
                },
                {
                    label: "RPM",
                    value: "RPM"
                },
                {
                    label: "Fill rate",
                    value: "FillRate"
                }
            ],
            DemandPartner: [
                {
                    label: "Requests",
                    value: "Requests"
                },
                {
                    label: "Impressions",
                    value: "Impressions"
                },
                {
                    label: "Revenue",
                    value: "Revenue"
                },
                {
                    label: "Bid rate",
                    value: "BidRate"
                }
            ],
            DemandTag: [
                {
                    label: "Requests",
                    value: "Requests"
                },
                {
                    label: "Impressions",
                    value: "Impressions"
                },
                {
                    label: "Revenue",
                    value: "Revenue"
                },
                {
                    label: "Bid rate",
                    value: "BidRate"
                }
            ],
            AllSupply: [
                {
                    label: "Requests",
                    value: "Requests"
                },
                {
                    label: "Impressions",
                    value: "Impressions"
                },
                {
                    label: "Revenue",
                    value: "Revenue"
                },
                {
                    label: "Render rate",
                    value: "RenderRate"
                },
                {
                    label: "Timeouts",
                    value: "Timeouts"
                },
                {
                    label: "RPM",
                    value: "RPM"
                },
                {
                    label: "Fill rate",
                    value: "FillRate"
                }
            ],
            AllDemand: [
                {
                    label: "Requests",
                    value: "Requests"
                },
                {
                    label: "Impressions",
                    value: "Impressions"
                },
                {
                    label: "Revenue",
                    value: "Revenue"
                },
                {
                    label: "Bid rate",
                    value: "BidRate"
                }
            ],
        },
        validation: {
            type: "required",
            errorMessage: "Metric selection is required.",
        },
    },
    {
        label: "Deviation type",
        type: "simple_select",
        key: "deviation_type",
        isRequired: true,
        isMutable: true,
        placeholder: "Select deviation type",
        areOptionsDependent: false,
        dependentOn: "alert_type",
        dependentKeyValue: "Trend",
        options: [
            { label: "Mean deviation", value: "MeanDeviation" },
            { label: "Standard deviation", value: "StandardDeviation" },
        ],
        validation: {
            type: "required",
            errorMessage: "Deviation type is required.",
        },
    },
    {
        label: "Threshold",
        type: "number",
        key: "threshold",
        isRequired: true,
        isMutable: true,
        dependentOn: "deviation_type",
        dependentKeyValue: "MeanDeviation",
        placeholder: "Enter threshold",
        validation: {
            min: 0,
            max: 100,
            errorMessage: "Threshold must be between 0 and 100.",
        },
    },
    {
        label: "Threshold",
        type: "simple_select",
        key: "threshold",
        isRequired: true,
        isMutable: true,
        dependentOn: "deviation_type",
        dependentKeyValue: "StandardDeviation",
        placeholder: "Select threshold",
        options: [
            { label: "1 standard", value: "1" },
            { label: "2 standard", value: "2" },
        ],
        validation: {
            type: "required",
            errorMessage: "Threshold is required.",
        },
    },
    {
        label: "Duration",
        type: "simple_select",
        key: "duration",
        isRequired: true,
        isMutable: true,
        placeholder: "Enter duration",
        showIcon: true,
        icon: <InfoIcon />,
        postfix: "hrs",
        areOptionsDependent: false,
        dependentOn: "alert_type",
        dependentKeyValue: "Spot",
        specialIcon: <InfoIcon />,
        popoverText: "Duration refers to N consecutive hours during which a metric's zero value will prompt an alert.",
        options: [
            { label: "1", value: "1" },
            { label: "2", value: "2" },
            { label: "3", value: "3" },
            { label: "4", value: "4" },
            { label: "5", value: "5" },
            { label: "6", value: "6" },
        ],
        validation: {
            type: "required",
            errorMessage: "Duration is required.",
        },
    },
    {
        label: "Severity",
        type: "simple_select",
        key: "severity",
        isRequired: true,
        isMutable: true,
        placeholder: "Select severity",
        areOptionsDependent: false,
        options: [
            {
                value: "Critical",
                customContent: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {AlertsSeverityIcons("Critical")}
                        <span style={{ marginLeft: "8px", color: ALERT_SEVERITY_COLORS["Critical"] }}>Critical</span>
                    </div>
                ),
            },
            {
                value: "High",
                customContent: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {AlertsSeverityIcons("High")}
                        <span style={{ marginLeft: "8px", color: ALERT_SEVERITY_COLORS["High"] }}>High</span>
                    </div>
                ),
            },
            {
                label: "Medium",
                value: "Medium",
                customContent: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {AlertsSeverityIcons("Medium")}
                        <span style={{ marginLeft: "8px", color: ALERT_SEVERITY_COLORS["Medium"] }}>Medium</span>
                    </div>
                ),
            },
            {
                value: "Low",
                customContent: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {AlertsSeverityIcons("Low")}
                        <span style={{ marginLeft: "8px", color: ALERT_SEVERITY_COLORS["Low"] }}>Low</span>
                    </div>
                )
            },
        ],
        validation: {
            type: "required",
            errorMessage: "Severity level is required.",
        },
    },
    {
        label: "Frequency",
        type: "simple_select",
        key: "frequency",
        isRequired: true,
        isMutable: true,
        placeholder: "Select frequency",
        dependentOn: "alert_type",
        dependentKeyValue: "Trend",
        areOptionsDependent: true,
        optionsDependentOn: "alert_type",
        specialIcon: <InfoIcon />,
        popoverText: "Frequency is how often a report is created and delivered.",
        validOptions: {
            Trend: [
                {label: "Daily", value: "Daily"},
                {label: "Weekly", value: "Weekly"},
            ],
            Spot: [
                {label: "Hourly", value: "Hourly"},
            ],
        },
        validation: {
            type: "required",
            errorMessage: "Frequency is required.",
        }
    },
    {
        label: "Status",
        type: "radio_button",
        key: "status",
        isRequired: true,
        isMutable: true,
        defaultValue: "true",
        areOptionsDependent: false,
        options: [
            { label: "Enabled", value: true},
            { label: "Disabled", value: false}
        ]
    },
    // use it when n8n integration is complete
    // {
    //     label: "Create jira ticket",
    //     type: "radio_button",
    //     key: "create_jira_ticket",
    //     specialIcon: <InfoIcon />,
    //     popoverText: "Jira ticket will be created in this board:",
    //     url:"" ,
    //     isRequired: true,
    //     isMutable: true,
    //     defaultValue: false,
    //     disableFeature: false,
    //     areOptionsDependent: false,
    //     options: [
    //         { label: "Enabled", value: true},
    //         { label: "Disabled", value: false}
    //     ]
    // }
    {
        label: "Create jira ticket",
        type: "view_only",
        key: "create_jira_ticket",
        isRequired: false,
        isMutable: false,
        value: "Under Development"
    }
]