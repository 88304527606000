import React, { useState } from "react"
import {Grid, Typography, InputAdornment, IconButton, Skeleton,} from "@mui/material";
import "./index.css"
import SearchIcon from "../../../assests/icons/searchIcon";
import { TextField } from "@mui/material";
import {FilterIcon} from "../../../assests/icons/FilterIcon";

function DynamicTableTopFilter(props) {


    const onSearchSetText = (e) => {
        props.onSearch(e.target.value);
    }

    const openFilterSidebar = () => {
        // Call the function passed from parent to open the filter sidebar
        if (props.filterOpen) {
            props.filterOpen();
        }
    };

    const paginatedCount = () => {
        let totalCount = props.totalCount;
        let minCount = (props.page - 1) * props.pageSize + 1;
        let maxCount = Math.min((props.page * props.pageSize), totalCount);

        if( totalCount === 0){
            return `0 ${props.titleText}`;
        }
        // else if(minCount === maxCount) return `last of ${totalCount} ${props.titleText}`;  // uncomment this to handle cases when only one item is there in the page
        else return `${minCount}-${maxCount} of ${totalCount} ${props.titleText}`;
    }


    return (
        <Grid item xs={12}>
            <Grid container className="amg-table-content">
                <Grid item xs={12} className="amg-table-content-filter" >
                    <Grid justifyContent="space-between" container>
                        {!props.isLoading?(
                            <Grid item xs className="amg-table-content-count">
                                {
                                    props?.countIsPaginated === true ? (
                                            <Typography>
                                                {paginatedCount()}
                                            </Typography>
                                        ) : (
                                            <Typography>
                                                {props?.count && props.count !== 0 ? props.count : "0"}
                                                {props?.totalCount && props.totalCount !== 0 ? ` of ${props.totalCount}` : ""}
                                                {props?.count && props.count !== 0 ?(` ${props.titleText} `): ""}
                                                {/*{props.showFilter &&(`| Last refreshed at ${formatDateForActivityLog(props.lastApiCallTime)}`) }*/}
                                                {/*{props.showFilter &&(<Button className="refresh-activity-log-btn" onClick={()=>props.onClickRefresh()}><RefreshIcon/>Refresh</Button> )}*/}
                                            </Typography>
                                        )
                                }


                            </Grid>):( <Skeleton animation="wave" className="log-header" width="20%" height={30} /> )}

                        <Grid item xs className="amg-table-content-search" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} >

                            <TextField
                                value={props.searchText}
                                onChange={(e) => onSearchSetText(e)}
                                placeholder={`Search`}
                                variant="outlined"
                                className="generic-search-container"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon></SearchIcon>
                                        </InputAdornment>
                                    ),
                                    inputProps: { 'data-testid': 'search-input' },
                                }}
                            />
                            {props.showFilter &&(
                                <div onClick={openFilterSidebar}>
                                    <IconButton className="activity-log-filter-icon-button"
                                                onClick={openFilterSidebar}><FilterIcon/>
                                        {props?.filterCount>0 &&(
                                            <div className="badge-total-filter-div"> {props.filterCount}</div>)}
                                    </IconButton></div>
                            )}

                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </Grid>
    )
}

export default DynamicTableTopFilter