import axios from "axios";
import { BFF_LAYER_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

export const getAlertsAggregatedStats = async(account_id) => {
    let data = JSON.stringify({
        query: `query MyQuery {
                      getAlertsAggregatedStats(account_id: ${account_id}) {
                        status
                        message
                        data {
                          total_channel_ids_affected
                          total_channels_affected
                          total_critical_alerts
                          total_demand_tag_ids
                          total_demand_tags_affected
                          total_high_alerts
                          total_medium_alerts
                          total_low_alerts
                          total_paused_alerts
                          total_supply_tag_ids
                          total_supply_tags_affected
                          total_unread_alerts
                        }
                      }
                    }`
    });
    const headers = adsHeader()

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BFF_LAYER_URI,
        headers: headers,
        data: data
    };

    return axios.request(config)
        .then((response) => {
            if (response?.data?.data?.getAlertsAggregatedStats && response?.data?.data?.getAlertsAggregatedStats?.status === 200) {
                return response.data.data.getAlertsAggregatedStats;
            } else {
                return [];
            }
        })
        .catch((error) => {
            console.error('Request failed:', error);
            return null;
        });
};
