import React from "react";
import PropTypes from "prop-types";

function AlertRulesBreadCrumbs (props) {
    return(
        <React.Fragment>
            <span
                onClick={() => props.onClickBreadCrumbsLink(
                    "/dashboard/alerts"
                )}
                className="amg-bread-crumbs-links"
                data-testid="breadcrumb-alerts-link"
            >
                Alerts &nbsp;
            </span>/ &nbsp;<span data-testid="breadcrumb-container"> Alert rules </span>
        </React.Fragment>
    )
}

AlertRulesBreadCrumbs.propTypes = {
    onClickBreadCrumbsLink: PropTypes.func,
    platformName: PropTypes.string,
    channelId: PropTypes.string
}

export default AlertRulesBreadCrumbs