import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import "./index.css";
import {Grid, Skeleton} from "@mui/material";
import Notification from "../../../Components/Notification";
import AlertDetailsBreadCrumbs from "../../../Components/BreadCrumbs/AlertsBreadCrumbs/AlertDetailsBreadcrumbs";
import {GETTING_THE_DATA, SUCCESS_NOTIFICATION_TYPE, USER_DATA_KEY} from "../../../Constant";
import {getLocalStorageData} from "../../../utils/localStorageHelper";
import {AlertsSeverityIcons} from "../../../assests/icons/AlertsSeverityIcons";
import Badge from "@mui/material/Badge";
import LinkIcon from "../../../assests/icons/LinkIcon";
import IconButton from "@mui/material/IconButton";
import EmailIcon from "../../../assests/icons/EmailIcon";
import PauseIcon from "../../../assests/icons/PauseIcon";
import JiraTicketIcon from "../../../assests/icons/JiraTicketIcon";
import ExternalLinkIcon from "../../../assests/icons/ExternalLinkIcon";
import DynamicTable from "../../../Components/Tables/DynamicTable";
import {issueDetailsTableConfig} from "../../../Configs/AlertsConfig/IssueDetailsTableConfig";
import {issueDetailTableContent} from "../../../Configs/AlertsConfig/MockData";
import {getAlertDetailById} from "../../../api/getAlertDetailById";
import useWebStorageHelper from "../../../utils/sessionStorageHelper";
import {RequestContext} from "../../../Context/RequestContext";

const AlertDetailsDashboard = () => {
    const navigation = useNavigate();
    const {alertGID, alertId } = useParams();
    const [notificationMessage, setNotificationMessage] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [alertType, setAlertType] = useState("");
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState({});
    const [allAlertDetails, setAllAlertDetails] = useState({});
    const { currentSelectedAlert} = useContext(RequestContext);
    console.log("loading", loading);

    const possibleNextSteps = [
        "Check the stream origin to ensure channel is working ok and ad markers (also called SCTEs) are inserted properly",
        "Check the player configuration to see if the stream URL is ok",
        "Check if the player requests have gone down in line with request drop"
    ]

    useEffect(() => {
        setIsOpen(true);
        setNotificationMessage(GETTING_THE_DATA);
        setAlertType(SUCCESS_NOTIFICATION_TYPE);
        loadData();
    }, []);

    const loadData = async () => {
        setLoading(true);
        let user_data = getLocalStorageData(USER_DATA_KEY, true);
        setUserData(user_data);

        let {email} = useWebStorageHelper.getItem(USER_DATA_KEY);
        const alertDetails = await getAlertDetailById(
            currentSelectedAlert?.alert_group_id,
            currentSelectedAlert?.alert_id,
            currentSelectedAlert?.account_id,
            email
        ).then((res) => {return res.data});

        setAllAlertDetails(alertDetails);
        setLoading(false);
    }
    const onClickBreadCrumbsLink = (link) => {
        navigation(link);
    };

    const handleNotificationClose = () => {
        setIsOpen(false);
    };

    const showStyledBadge = (severity) => {
        const color = severity.toLowerCase() === "critical" ? "#891C1A" : severity.toLowerCase() === "high" ? "#891C1A" : severity.toLowerCase() === "medium" ? "#F1B521" : severity.toLowerCase() === "low" ? "#82C77F" : "black";
        return (
            <Badge
                overlap="rectangular"
                sx={{
                    "& .MuiBadge-badge": {backgroundColor: color, color: "#E7E7E7", padding: "4px 8px", height: "20px", minWidth: "30px", borderRadius: "16px", display: "flex", alignItems: "center", fontFamily: "Roboto", fontSize: "11px", fontWeight: 400, lineHeight: "14px", letterSpacing: "0.22px", gap: "4px"},
                }}
                badgeContent={
                    <div style={{ display: "flex", alignItems: "center", padding: "1px" }}>
                        {AlertsSeverityIcons(severity, "#E7E7E7")}
                        <span>{severity}</span>
                    </div>
                }
            />
        );
    };

    return (
        <React.Fragment>
            <Grid container >
                {allAlertDetails && allAlertDetails.alert_id ?
                    <>
                            <Grid item xs={12} className="amg-bread-crumbs">
                                <AlertDetailsBreadCrumbs
                                    onClickBreadCrumbsLink={onClickBreadCrumbsLink}
                                    alertName={currentSelectedAlert?.alert_rule_name}
                                />
                            </Grid>

                            <Grid container className="alert-details-header" >
                                <Grid item xs={12} lg={8} md={8} >
                                    <div className="alert-name-label"> Alert name </div>
                                    {/*//change severity to dynamic*/}
                                    <div className="alert-name-value">
                                        <span>{allAlertDetails?.alert_rule_name}</span>
                                        {showStyledBadge(allAlertDetails?.severity)}
                                    </div>
                                    <div className="alert-more-details">
                                        <div className="alert-more-details-sub">
                                            <span className="alert-name-label">Alert type</span>
                                            <span className="alert-more-details-value">{allAlertDetails?.alert_type}</span>
                                        </div>
                                        <div className="alert-more-details-sub">
                                            <span className="alert-name-label">Channel</span>
                                            <span className="alert-more-details-value blue-text">{allAlertDetails?.channel_name}</span>
                                        </div>
                                        <div className="alert-more-details-sub">
                                            <span className="alert-name-label">{allAlertDetails?.entity_type}</span>
                                            <span className="alert-more-details-value blue-text">{allAlertDetails?.entity_name}</span>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={4} md={4} >
                                    <div className="alert-details-actions-div">
                                        {/*MAKE THEM DISABLED*/}
                                        <IconButton disabled={true} className="alerts-action-button" >
                                            {<LinkIcon />}
                                        </IconButton>
                                        <IconButton disabled={true} className="alerts-action-button" >
                                            {<EmailIcon />}
                                        </IconButton>
                                        <IconButton disabled={true} className="alerts-action-button" >
                                            {<PauseIcon />}
                                            <span>Pause alert</span>
                                        </IconButton>
                                        <IconButton disabled={true} className="alerts-action-button" >
                                            {<JiraTicketIcon />}
                                            <span>Create jira ticket</span>
                                        </IconButton>

                                    </div>
                                </Grid>
                            </Grid>

                            <div className="alert-details-body">
                                <Grid item xs={12} lg={6} md={6} className="alert-details-body-sub">
                                    <div className="alert-details-subheading">Alert details</div>
                                    <div className="alert-details-row">
                                        <span> Alert ID </span>
                                        <span> {allAlertDetails?.alert_id} </span>
                                    </div>
                                    <div className="alert-details-row">
                                        <span> Time of issue </span>
                                        <span> {allAlertDetails?.created_at} </span> {/*convert into UTC*/}
                                    </div>
                                    <div className="alert-details-row">
                                        <span> Metric </span>
                                        <span> {allAlertDetails?.metric_name} </span>
                                    </div>
                                    <div className="alert-details-row">
                                        <span> Metric calculation </span>
                                        {/*check if it is correct or not with siva*/}
                                        <span> Sum of non empty vast errors / Sum of demand requests * 100 fluctuations </span>
                                    </div>
                                    {allAlertDetails?.alert_type?.toLowerCase() === "trend" && (
                                        <div className="alert-details-row">
                                            <span> Metric deviation </span>
                                            <span> Standard </span>
                                        </div>
                                    )}


                                    <div className="alert-details-row">
                                        <span> Threshold </span>
                                        {/*in case of trend it will be 1or 2 standard deviation and in spot it will be in percentage or vice versa check with piyush*/}
                                        {allAlertDetails?.alert_type?.toLowerCase() === "trend" ?
                                            <span> {allAlertDetails?.threshold} standard deviation </span> :
                                            <span> {allAlertDetails?.threshold} % </span>
                                        }

                                    </div>
                                    <div className="alert-details-row">
                                        <span> Issue detail </span>
                                        <div className="issue-details-table" >
                                            <DynamicTable
                                                tableConfig={issueDetailsTableConfig}
                                                tableContent={allAlertDetails?.metrics}
                                                highlightUnseen={false}
                                                transparentRow={true}
                                                applyBorder={true}
                                                forAlertDetailPage={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="vector-full-width"></div>
                                    <div className="alert-details-row">
                                        <span> Alert frequency </span>
                                        <span> {allAlertDetails?.frequency} </span>
                                    </div>
                                    {allAlertDetails?.alert_logging_info && allAlertDetails?.alert_logging_info?.jira && (
                                        <div className="alert-details-row">
                                            <span> Jira ticket </span>
                                            <span
                                            //     onClick={
                                            //     () => {
                                            //         window.open("https://www.google.com/", "_blank");
                                            //     }
                                            // }
                                                  className="jira-links"> under development <ExternalLinkIcon/> </span>

                                        </div>
                                    )}

                                </Grid>
                                <Grid item xs={12} lg={6} md={6} className="alert-details-body-sub">
                                    <span className="alert-more-details-value">Recurrence graph is under development and will be displayed here...</span>
                                </Grid>
                            </div>

                            <Grid container xs={12} className="alert-details-footer">
                                <div className="alert-details-subheading"> Possible next steps</div>
                                <div className="alert-details-footer-list">
                                    {possibleNextSteps.map((step, index) => {
                                        return (
                                            <div key={index} className="alert-details-footer-list-item">
                                                <span> {`${index + 1}. ${step}`} </span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </Grid>
                    </>
                    :
                            <Grid item xs={10} className="item-padding-text">
                                <Skeleton animation="wave" className="loading-item-data"/>
                            </Grid>
                    }
            </Grid>
            <Notification
                open={isOpen}
                message={notificationMessage}
                handleClose={handleNotificationClose}
                type={alertType}
            />
        </React.Fragment>
    );
};

export default AlertDetailsDashboard;
