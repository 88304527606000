import React, { useState, useEffect } from "react";
import { Pagination, Select, MenuItem, Grid, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./index.css";
import { ALERTS_PAGINATION_OPTIONS } from "../../Constant";

const CustomPagination = ({
                              totalItems,
                              itemsPerPage,
                              onPageChange,
                              currentPage
                          }) => {
    const [pageSize, setPageSize] = useState(itemsPerPage);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(Math.ceil(totalItems / pageSize));

    useEffect(() => {
        onPageChange(page, pageSize);
    }, [page, pageSize]);

    useEffect(() => {
        setTotalPages(Math.ceil(totalItems / pageSize));
    }, [totalItems, pageSize]);

    useEffect(() => {
        setPage(currentPage);
    }, [currentPage]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handlePageSizeChange = (event) => {
        const newSize = Number(event.target.value);
        setPageSize(newSize);
        setPage(1);
    };

    return (
        <React.Fragment>
            <Grid item>
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                    siblingCount={1}
                    boundaryCount={1}
                    showFirstButton
                    showLastButton
                    className="pagination-root"
                />
            </Grid>
            <Grid item>
                <Select
                    value={pageSize}
                    onChange={handlePageSizeChange}
                    variant="outlined"
                    size="small"
                    IconComponent={KeyboardArrowDownIcon}
                    classes={{ icon: "select-icon" }}
                    className="select-root"
                >
                    {ALERTS_PAGINATION_OPTIONS.map((size) => (
                        <MenuItem
                            key={size}
                            value={size}
                            className={size === pageSize ? "menu-item-selected" : ""}
                        >
                            {size}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item>
                <Typography>per page</Typography>
            </Grid>
        </React.Fragment>
    );
};

export default CustomPagination;
