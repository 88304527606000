import React, {useEffect} from 'react';
import "./index.css";
import {Button, Grid, Tooltip, Typography} from "@mui/material";
import SingleSelectDropdown from "../../Common/CustomFormInputs/SingleSelectDropdown";
import MultiSelectDropdownWithSearch from "../../Common/CustomFormInputs/MultiSelectDropdownWithSearch";
import SimpleTextInput from "../../Common/CustomFormInputs/SimpleTextInput";
import NumberInput from "../../Common/CustomFormInputs/NumberInput";
import RadioButtonInput from "../../Common/CustomFormInputs/RadioButtonInput";
import SingleSelectAutocomplete from "../../Common/CustomFormInputs/SingleSelectAutocomplete";
import {isEmpty} from "../../utils/helper";

export const DynamicSidebarForm = (props) => {

    const handleCloseForm = () => {
        props.handleCloseForm(false);
    };

    const handleSubmit = () => {
        props.handleSubmit();
    }

    const renderLabel = (item) => {
        return (
            <div className="form-labels">
                <span>{item.label}</span>
                { item.isRequired && <span style={{ color: "#fda560", marginLeft: "4px" }} >*</span> }
                {item.specialIcon && (
                    <Tooltip
                        title={
                            <Typography className="tooltip-info">
                                {item.popoverText}{" "}
                                { item.url && (<a
                                    href={item.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{color: "#7FB8F0", textDecoration: "underline"}}
                                >
                                    {item.url}
                                </a>)}
                            </Typography>
                        }
                        arrow
                        componentsProps={{
                            tooltip: {
                                sx:{
                                    background: "#323232",
                                    borderRadius: "4px",
                                }
                            }
                        }}
                    >
                    <span style={{ cursor: "pointer", marginTop: "2px", paddingLeft: "4px" }}>
                        {item.specialIcon}
                    </span>
                    </Tooltip>
                )}
            </div>
        );
    };

    const getFilteredOptions = (item) => {
        if (item.areOptionsDependent && item.optionsDependentOn) {
            const dependentValue = props.formValues[item.optionsDependentOn];
            return item.validOptions?.[dependentValue] || [];
        }
        return item.options || [];
    };

    const shouldRenderField = (item) => {
        if( item.onlyRenderOn) {
            return !isEmpty(props.formValues[item.onlyRenderOn])
        }
        if (item.dependentOn && item.dependentKeyValue) {
            return props.formValues[item.dependentOn] === item.dependentKeyValue;
        }
        return true;
    };

    useEffect(() => {}, [props.formValues]);

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                {props.formConfig.map((item, index) => {
                    if (!shouldRenderField(item)) {
                        return null; // Skip rendering this field if dependencies are not met
                    }
                    return (
                        <Grid item xs={12} className="form-row" key={index}>
                            { item.type === "header" && (
                                <Grid item xs={12} className="create-rules-header">
                                    <div>{ !props.isEditCall ? item.label: item.editLabel }</div>
                                    <div onClick={handleCloseForm} className="close-sidebar" data-testid="close-sidebar">
                                        {item.iconAsset}
                                    </div>
                                </Grid>
                            )}
                            { item.type === "vector" && <div className="vector-full-width"></div>}
                            { item.type === "info" && (
                                <Grid item xs={12} className="info-grid">
                                    <div
                                        className="info-row"
                                        style={{
                                            backgroundColor: item.backgroundColor,
                                            display: "flex",
                                        }}
                                    >
                                        {item.iconAsset}
                                        <div style={{ color: item.textColor }}>{item.label}</div>
                                    </div>
                                </Grid>
                            )}
                            { item.type === "simple_select" && (
                                <Grid item xs={12} className="form-fields">
                                    <div className="form-row-flex">
                                        <div className="form-labels-container">{renderLabel(item)}</div>
                                        <div className="form-input-container">
                                            <SingleSelectDropdown
                                                disabled={!item.isMutable ? !!props?.isEditCall : false}
                                                options={getFilteredOptions(item)}
                                                value={props.formValues[item.key]}
                                                placeholder={item.placeholder}
                                                required={item.isRequired}
                                                errorMessage={item.validation?.errorMessage}
                                                onChange={(value) =>
                                                    props.handleInputChange(item.key, value)
                                                }
                                                width={item.inputWidth}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            )}
                            { item.type === "simple_select_autocomplete" && (
                                <Grid item xs={12} className="form-fields">
                                    <div className="form-row-flex">
                                        <div className="form-labels-container">{renderLabel(item)}</div>
                                        <div className="form-input-container">
                                            <SingleSelectAutocomplete
                                                disabled={!item.isMutable ? !!props?.isEditCall : false}
                                                options={getFilteredOptions(item)}
                                                value={props.formValues[item.key]}
                                                placeholder={item.placeholder}
                                                required={item.isRequired}
                                                errorMessage={item.validation?.errorMessage}
                                                onChange={(value) =>
                                                    props.handleInputChange(item.key, value)
                                                }
                                                width={item.inputWidth}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            )}
                            { item.type === "multi_select_with_search" && (
                                <Grid item xs={12} className="form-fields">
                                    <div className="form-row-flex">
                                        <div className="form-labels-container">{renderLabel(item)}</div>
                                        <div className="form-input-container">
                                            <MultiSelectDropdownWithSearch
                                                disabled={!item.isMutable ? !!props?.isEditCall : false}
                                                options={getFilteredOptions(item)}
                                                value={props.formValues[item.key]}
                                                placeholder={item.placeholder}
                                                allowSelectAll={item.allowSelectAll}
                                                required={item.isRequired}
                                                errorMessage={item.validation?.errorMessage}
                                                onChange={(values) =>
                                                    props.handleInputChange(item.key, values, true)
                                                }
                                                width={item.inputWidth}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            )}
                            {
                                item.type === "view_only" && (item.label === "Alert name" || item.label === "Create jira ticket") && (
                                    <Grid item xs={12} className="form-fields">
                                        <div className="form-row-flex">
                                            <div className="form-labels-container">{renderLabel(item)}</div>
                                            <div className="form-input-container">
                                                <Typography className="form-value-text">
                                                    {props?.formValues[item.key]}
                                                </Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                )
                            }
                            { item.type === "number" && (
                                <Grid item xs={12} className="form-fields">
                                    <div className="form-row-flex">
                                        <div className="form-labels-container">{renderLabel(item)}</div>
                                        <div className="form-input-container">
                                            <NumberInput
                                                disabled={!item.isMutable ? !!props?.isEditCall : false}
                                                value={props.formValues[item.key]}
                                                placeholder={item.placeholder}
                                                required={item.isRequired}
                                                errorMessage={item.validation?.errorMessage}
                                                onChange={(values) =>
                                                    props.handleInputChange(item.key, values)
                                                }
                                                width={item.inputWidth}
                                                min={item.validation.min}
                                                max={item.validation.max}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            )}
                            { item.type === "string" && (
                                <Grid item xs={12} className="form-fields">
                                    <div className="form-row-flex">
                                        <div className="form-labels-container">{renderLabel(item)}</div>
                                        <div className="form-input-container">
                                            <SimpleTextInput
                                                disabled={!item.isMutable ? !!props?.isEditCall : false}
                                                value={props.formValues[item.key]}
                                                placeholder={item.placeholder}
                                                required={item.isRequired}
                                                errorMessage={item.validation?.errorMessage}
                                                onChange={(values) =>
                                                    props.handleInputChange(item.key, values)
                                                }
                                                validationPattern = {item.validation?.validationPattern}
                                                validationErrorMessage = {item.validation?.validationErrorMessage}
                                                maxLength = {item.validation?.maxLength}
                                                width={item.inputWidth}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            )}
                            {item.type === "radio_button" && (
                                <Grid item xs={12} className="form-fields">
                                    <div className="form-row-flex">
                                        <div className="form-labels-container">{renderLabel(item)}</div>
                                        <div className="form-input-container">
                                            <RadioButtonInput
                                                disabled={item.disableFeature || !item.isMutable ? !!props?.isEditCall : false}
                                                value={props.formValues[item.key]}
                                                onChange={(value) => props.handleInputChange(item.key, value)}
                                                label={item.label}
                                                options={item.options}
                                                required={item.isRequired}
                                                errorMessage={item.validation?.errorMessage}
                                                popoverText={item.popoverText}
                                                boardLink={item.boardLink}
                                                isRow={true}
                                                width={item.inputWidth}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            )}
                            {item.type === "read_only" && (
                                <Grid item xs={12} className="form-fields">
                                    <div className="form-row-flex">
                                        <div className="form-labels-container">{renderLabel(item)}</div>
                                        <div className="form-input-container">
                                            <div className="read-only-value">{props.formValues[item.key]}</div>
                                        </div>
                                    </div>
                                </Grid>
                            )}

                        </Grid>
                    );
                })}
            </Grid>
            <Grid container className="sidebar-actions-container">
                <Button className="report-cancel-button" onClick={handleCloseForm}>
                    Cancel
                </Button>
                {!props?.allowSubmit ? (
                        <Button className="orange-button-disabled" disabled >
                            {props.submitButtonText || "Save"}
                        </Button>

                ) : (
                        <Button className="orange-button" onClick={handleSubmit}  >
                            {props.submitButtonText || "Save"}
                        </Button>
                )}
            </Grid>
        </React.Fragment>
    );
};
